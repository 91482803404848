import "@js/theme/index";
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $(".js-header-top").outerHeight();
var wrapperHeader = $(".js-header-top-wrapper");
wrapperHeader.css("height", wrapperHeader.outerHeight());
$(window).scroll(function(event) {
  var st = $(this).scrollTop();
  var windowWidth = $(window).width();
  var stickyMenuExists = false;
  if ($("body").hasClass("page-product")) {
    stickyMenuExists = $(".sticky").length > 0;
  }
  if (Math.abs(lastScrollTop - st) <= delta) {
    return;
  }
  if (windowWidth >= 1024) {
    var headerHeight = $(".js-header-top").outerHeight();
    if (st > 100) {
      $(".js-header-top").addClass("header-fixed-small");
      $(".js-header-top").css("position", "fixed");
      $(".ui-autocomplete.ui-jolisearch").css("display", "none");
      wrapperHeader.css("padding-top", headerHeight + "px");
    } else {
      $(".js-header-top").removeClass("header-fixed-small");
      $(".js-header-top").css("position", "static");
      wrapperHeader.css("padding-top", "0");
    }
  } else {
    var mobileHeaderHeight = $(".js-header-top").outerHeight();
    if (stickyMenuExists) {
      if (st > lastScrollTop && st > navbarHeight) {
        $(".js-header-top").css("top", -mobileHeaderHeight + "px");
        $("#_desktop_top_menu .main-menu__sub").addClass("superhide");
        $(".js-header-top").addClass("header-fixed-small");
        $(".ui-autocomplete.ui-jolisearch").css("display", "none");
        wrapperHeader.css("padding-top", mobileHeaderHeight + "px");
      }
    } else {
      if (st > lastScrollTop && st > navbarHeight) {
        $(".js-header-top").css("top", -mobileHeaderHeight + "px");
        $("#_desktop_top_menu .main-menu__sub").addClass("superhide");
        $(".js-header-top").addClass("header-fixed-small");
        $(".ui-autocomplete.ui-jolisearch").css("display", "none");
        wrapperHeader.css("padding-top", mobileHeaderHeight + "px");
      } else {
        if (st + $(window).height() < $(document).height()) {
          $(".js-header-top").css("top", "0");
          $("#_desktop_top_menu .main-menu__sub").removeClass("superhide");
          wrapperHeader.css("padding-top", "0");
        }
      }
    }
  }
  lastScrollTop = st;
});
