var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Grid
} from "swiper";
import DynamicImportSwiperModule from "@js/theme/components/sliders/DynamicImportSwiperModule";
const dynamicModulesMap = {
  thumbs: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/thumbs/thumbs.js")
    ]
  ),
  virtual: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/virtual/virtual.js"),
      import("@node_modules/swiper/modules/virtual/virtual.scss")
    ]
  ),
  keyboard: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/keyboard/keyboard.js")
    ]
  ),
  mousewheel: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/mousewheel/mousewheel.js")
    ]
  ),
  scrollbar: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/scrollbar/scrollbar.js"),
      import("@node_modules/swiper/modules/scrollbar/scrollbar.scss")
    ]
  ),
  parallax: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/parallax/parallax.js")
    ]
  ),
  zoom: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/zoom/zoom.js"),
      import("@node_modules/swiper/modules/zoom/zoom.scss")
    ]
  ),
  freeMode: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/free-mode/free-mode.js"),
      import("@node_modules/swiper/modules/free-mode/free-mode.scss")
    ]
  ),
  controller: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/controller/controller.js"),
      import("@node_modules/swiper/modules/controller/controller.scss")
    ]
  ),
  grid: new DynamicImportSwiperModule(
    () => [
      import("@node_modules/swiper/modules/grid/grid.js"),
      import("@node_modules/swiper/modules/grid/grid.scss")
    ]
  )
};
const defaultModules = [Navigation, Pagination, Autoplay, Grid];
class SwiperSlider {
  constructor(target, options) {
    this.target = target;
    this.options = options;
    this.modules = defaultModules;
    this._modulesToFetch = [];
    this.SwiperInstance = null;
  }
  initSlider() {
    return __async(this, null, function* () {
      this.findNeededModulesToFetch();
      yield this.fetchNeededModules();
      yield this.initSwiper();
      return this.SwiperInstance;
    });
  }
  initSwiper() {
    this.SwiperInstance = new Swiper(this.target, __spreadProps(__spreadValues({}, this.options), {
      modules: this.modules
    }));
  }
  fetchNeededModules() {
    return __async(this, null, function* () {
      if (this._modulesToFetch.length > 0) {
        const modulesPromisesArray = [];
        for (const module of this._modulesToFetch) {
          modulesPromisesArray.push(module.getFiles());
        }
        const allPromises = Promise.all(
          modulesPromisesArray.map((innerModulesPromisesArray) => Promise.all(innerModulesPromisesArray))
        );
        return allPromises.then((arrayOfModules) => {
          for (const moduleImported of arrayOfModules) {
            for (const module of moduleImported) {
              if (typeof module.default !== "undefined") {
                this.modules = [...this.modules, module.default];
              }
            }
          }
        });
      }
      return Promise.resolve();
    });
  }
  findNeededModulesToFetch() {
    for (const dynamicModuleProp in dynamicModulesMap) {
      if (Object.prototype.hasOwnProperty.call(dynamicModulesMap, dynamicModuleProp) && typeof this.options[dynamicModuleProp] !== "undefined") {
        this._modulesToFetch.push(dynamicModulesMap[dynamicModuleProp]);
      }
    }
  }
}
export default SwiperSlider;
