var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import $ from "jquery";
import "@js/theme/vendors/bootstrap/bootstrap-imports";
import "bootstrap-touchspin";
import "jquery-hoverintent";
import "@js/theme/components/dynamic-bootstrap-components";
import bsCustomFileInput from "bs-custom-file-input";
import "@js/theme/components/selectors";
import "@js/theme/components/sliders";
import "@js/theme/components/responsive";
import "@js/theme/components/customer";
import "@js/theme/components/quickview";
import "@js/theme/components/product";
import "@js/theme/components/cart/cart";
import "@js/theme/components/cart/block-cart";
import usePasswordPolicy from "@js/theme/components/usePasswordPolicy";
import prestashop from "prestashop";
import EventEmitter from "events";
import Form from "@js/theme/components/form";
import TopMenu from "@js/theme/components/TopMenu";
import PageLazyLoad from "@js/theme/components/Lazyload";
import PageLoader from "@js/theme/components/PageLoader";
import useStickyElement from "@js/theme/components/useStickyElement";
for (const i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}
prestashop.pageLazyLoad = new PageLazyLoad({
  selector: ".lazyload"
});
prestashop.pageLoader = new PageLoader();
function accLinksTriggerActive() {
  const url = window.location.pathname;
  $(".js-customer-links a").each((i, el) => {
    const $el = $(el);
    if ($el.attr("href").indexOf(url) !== -1) {
      $el.addClass("active");
    }
  });
}
function initStickyHeader() {
  const header = document.querySelector(".js-header-top");
  const headerWrapper = document.querySelector(".js-header-top-wrapper");
  if (header && headerWrapper) {
    useStickyElement(header, headerWrapper);
  }
}
$(() => {
  initStickyHeader();
  accLinksTriggerActive();
  Form.init();
  bsCustomFileInput.init();
  const topMenu = new TopMenu("#_desktop_top_menu .js-main-menu");
  usePasswordPolicy(".field-password-policy");
  topMenu.init();
  $(".js-select-link").on("change", ({ target }) => {
    window.location.href = $(target).val();
  });
});
function myCustomSlider() {
  return __async(this, null, function* () {
    if ($("body").is("#index")) {
      const customThumbslider = new prestashop.SwiperSlider(".custom-thumbslider", {
        slidesPerView: 5,
        spaceBetween: 15,
        breakpoints: {
          320: {
            slidesPerView: 5
          }
        },
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      });
      const customThumbsliderSwiperInstance = yield customThumbslider.initSlider();
      const galleryCustomElem = document.querySelector(".custom-homeslider");
      const outerContener = document.querySelector(".homeslider-wrapper");
      const getDealy = outerContener.getAttribute("data-delay");
      const customHomeslider = new prestashop.SwiperSlider(".custom-homeslider", {
        slidesPerView: 1,
        loop: 1,
        autoplay: {
          delay: getDealy,
          disableOnInteraction: false
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          }
        },
        navigation: {
          nextEl: outerContener.querySelector(".swiper-button-next"),
          prevEl: outerContener.querySelector(".swiper-button-prev")
        },
        pagination: {
          el: galleryCustomElem.querySelector(".swiper-pagination"),
          type: "bullets"
        },
        thumbs: {
          swiper: customThumbsliderSwiperInstance
        }
      });
      const customHomesliderSwiperInstance = yield customHomeslider.initSlider();
    }
  });
}
myCustomSlider();
function myCustomSliderRows() {
  return __async(this, null, function* () {
    if ($("body").is("#index")) {
      const outerContenerRows = document.querySelector(".featured-products__navigation-one");
      const customHomesliderRows = new prestashop.SwiperSlider(".custom-homesliderRowsOne", {
        slidesPerView: 2,
        spaceBetween: 15,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          1024: {
            slidesPerView: 3,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 15
          },
          1200: {
            slidesPerView: 4,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 15
          },
          1680: {
            slidesPerView: 4,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 30
          }
        },
        navigation: {
          nextEl: outerContenerRows.querySelector(".swiper-button-next"),
          prevEl: outerContenerRows.querySelector(".swiper-button-prev")
        }
      });
      const customHomesliderSwiperInstanceRows = yield customHomesliderRows.initSlider();
    }
  });
}
myCustomSliderRows();
function myCustomSliderRowsTwo() {
  return __async(this, null, function* () {
    if ($("body").is("#index")) {
      const outerContenerRowsTwo = document.querySelector(".featured-products__navigation-two");
      const customHomesliderRowsTwo = new prestashop.SwiperSlider(".custom-homesliderRowsTwo", {
        slidesPerView: 2,
        spaceBetween: 15,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          1024: {
            slidesPerView: 3,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 15
          },
          1200: {
            slidesPerView: 4,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 15
          },
          1680: {
            slidesPerView: 4,
            grid: {
              fill: "column",
              rows: 2
            },
            spaceBetween: 30
          }
        },
        navigation: {
          nextEl: outerContenerRowsTwo.querySelector(".swiper-button-next"),
          prevEl: outerContenerRowsTwo.querySelector(".swiper-button-prev")
        }
      });
      const customHomesliderSwiperInstanceRowsTwo = yield customHomesliderRowsTwo.initSlider();
    }
  });
}
myCustomSliderRowsTwo();
